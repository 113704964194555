define('frontend/components/boxberry-question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['boxberry-question'],
    classNameBindings: ['question.isActive:active'],
    store: Ember.inject.service(),
    question: null,

    answers: Ember.computed('questions', function () {
      return Ember.get(this, 'questions.answers');
    }),

    isAnswered: false,
    isCorrectAnswer: false,
    selectedAnswer: null,
    showNext: false,
    answerText: null,

    actions: {
      selectAnswer(answer) {
        if (answer.isDisable || answer.isAnswered) {
          return false;
        }

        Ember.get(this, 'question.answers').forEach(element => {
          return element.id === answer.id ? Ember.set(element, 'isAnswered', true) // set Answer as Answered
          : Ember.set(element, 'isDisable', true); // set next answer as Disabled
        });

        Ember.set(this, 'answerText', answer.description);

        Ember.set(this, 'isAnswered', true);
        Ember.set(this, 'selectedAnswer', answer);

        if (answer.id === Ember.get(this, 'question.correctAnswer')) {
          Ember.get(this, 'setResults')(answer.id);
          Ember.set(this, 'isCorrectAnswer', true);
        } else {
          Ember.set(this, 'isCorrectAnswer', false);
        }

        Ember.set(this, 'showNext', true);

        // setTimeout(() => {
        //   let questionHeight = this.$().height();
        //   this.nextQuestion(get(this, 'question'), questionHeight);
        // }, 2000);

        // this.nextQuestion(get(this, 'question'));
      },

      nextQuestion(question) {
        Ember.set(this, 'answerText', null);
        Ember.get(this, 'nextQuestion')(question);
      },

      endQuiz(question) {
        Ember.set(this, 'answerText', null);
        Ember.get(this, 'endQuiz')(question);
      }
    }
  });
});