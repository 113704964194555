define('frontend/routes/village-mts', ['exports', 'axios'], function (exports, _axios) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    store: Ember.inject.service('store'),

    model() {
      return Ember.get(this, 'store').queryRecord('poll', {});
    },

    afterModel(model) {
      this._super(...arguments);
      const allowedOrigins = ['http://127.0.0.1:5500', 'http://localhost:4700', 'https://quizmachine.io', 'https://www.the-village.ru', 'https://old.the-village.ru'];

      window.addEventListener('message', function (e) {
        if (!allowedOrigins.includes(e.origin)) {
          return;
        }
        const payload = e.data !== '' ? JSON.parse(e.data) : {};
        console.error('payload', payload);

        if (payload.method === 'get') {
          const participantId = payload.data ? JSON.parse(payload.data).participantId : null;
          console.error('participantId', participantId);

          if (!participantId) {
            return;
          }

          const pollUrl = 'http://api.quizmachine.io/v1/polls/5f47d8c75d16c24dfd0dd5ec';
          // const pollUrl = 'http://localhost:3000/v1/polls/5f464f08f8eee628c907c960';

          _axios.default.get(`${pollUrl}/participant/${participantId}`).then(response => {
            Ember.set(model, 'isAnswered', true);
            Ember.set(model, 'selectedAnswer', response.data.idOfvote);
          }).catch(function (error) {
            console.error('Error while getting participant by Id', error);
          });
        }
      });
    },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});