define('frontend/routes/village-sadko', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    classNames: ['village-sadko-body-class'],
    store: Ember.inject.service('store'),

    model(params) {
      return Ember.get(this, 'store').findAll('sadko-item');
    },

    afterModel(model, params) {
      this._super(...arguments);
    },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});