define('frontend/components/yota-presents-person/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['yota-presents-person'],
    classNameBindings: ['isOpen:opened'],
    store: Ember.inject.service(),
    person: null,

    wishes: Ember.computed('person.wishes', function () {
      return Ember.get(this, 'person.wishes');
    }),

    isOpen: false,
    isWishSelected: false,
    selectedWish: null,

    actions: {
      openWishes() {
        Ember.set(this, 'isOpen', true);
      },

      selectWish(wish) {
        Ember.set(this, 'isWishSelected', true);
        Ember.set(this, 'selectedWish', wish);
        Ember.set(this, 'isOpen', false);

        const personId = Ember.get(this, 'person.id');
        Ember.get(this, 'setPersonPresent')(personId, wish);
      }
    }
  });
});