define('frontend/components/yota-paging-element/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['yota-paging-element'],
    classNameBindings: ['isActive:active', 'isCurrent:current', 'isMobile:is-mobile'],
    tagName: 'li',
    question: null,
    isMobile: false,

    isCurrent: Ember.computed('question.isActive', function () {
      return Ember.get(this, 'question.isActive');
    }),

    isActive: Ember.computed('question.isCanReturn', function () {
      return Ember.get(this, 'question.isCanReturn');
    }),

    click() {
      if (!Ember.get(this, 'isActive')) {
        return false;
      }

      if (Ember.get(this, 'returnToQuestion')) {
        Ember.get(this, 'returnToQuestion')(Ember.get(this, 'question'));
      }
    }
  });
});