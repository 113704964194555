define('frontend/components/boker-answer-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['boker-answer-button'],
    classNameBindings: ['isSelected:selected'],
    store: Ember.inject.service(),
    answer: null,
    isSelected: false,

    click() {
      if (Ember.get(this, 'onClick')) {
        Ember.set(this, 'isSelected', true);
        Ember.get(this, 'onClick')(Ember.get(this, 'answer'));
      }
    }
  });
});