define('frontend/components/yota-name-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['yota-name-field'],
    inputValue: '',

    inFocus: false,
    isFilled: Ember.computed('inputValue', function () {
      return this.inputValue !== '';
    }),

    focusIn() {
      Ember.set(this, 'inFocus', true);
    },
    focusOut() {
      Ember.set(this, 'inFocus', false);

      this.setUserName(this.inputValue);
    },

    actions: {}
  });
});