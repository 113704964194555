define('frontend/controllers/village-mts', ['exports', 'axios'], function (exports, _axios) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    poll: Ember.computed.alias('model'),

    actions: {
      setAnswer(answer) {
        const pollUrl = 'http://api.quizmachine.io/v1/polls/5f47d8c75d16c24dfd0dd5ec';
        // const pollUrl = 'http://localhost:3000/v1/polls/5f464f08f8eee628c907c960';

        _axios.default.post(pollUrl, {
          idOfvote: answer._id
        }).then(response => {
          const data = response.data;

          Ember.set(this, 'poll.items', data.items);
          Ember.set(this, 'poll.total', data.total);

          this._setParticipant(data.participant._id);

          setTimeout(() => {
            Ember.set(this, 'poll.isAnswered', true);
            Ember.set(this, 'poll.selectedAnswer', answer._id);
          }, 200);
        }).catch(error => {
          console.error('Error while post vote', error);
        });
      }
    },

    _setParticipant(participantId) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'storage#set',
          data: {
            participantId: participantId
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});