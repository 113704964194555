define('frontend/models/sadko-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    name: attr('string'),
    pic_1: attr('string'),
    pic_2: attr('string'),
    q_1: attr(),
    q_2: attr(),
    q_3: attr(),
    description: attr(),

    isActive: attr('boolean', {
      defaultValue: false
    }),

    isAnswered: attr('boolean', {
      defaultValue: false
    })
  });
});