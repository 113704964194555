define('frontend/models/pizza-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    title: attr('string'),
    text: attr('string'),
    isCorrect: attr('boolean'),

    isChecked: attr('boolean', {
      defaultValue: false
    }),

    isShow: attr('boolean', {
      defaultValue: true
    })
  });
});