define('frontend/controllers/village-pizza', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    items: Ember.computed.alias('model'),
    isCorrectAnswer: false,

    actions: {
      clickOnItem(item) {
        if (!item.isCorrect) {
          Ember.set(item, 'isShow', false);
        } else {
          Ember.set(this, 'isCorrectAnswer', true);
        }
      }
    }
  });
});