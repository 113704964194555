define('frontend/models/yota-app', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    title: attr('string'),
    img_url: attr('string'),
    img_active_url: attr('string'),
    query_code: attr('string'),
    group_id: attr('string'),

    status: attr('boolean', {
      defaultValue: false
    })
  });
});