define('frontend/routes/village-yota-dec', ['exports', 'ember-body-class/mixins/body-class'], function (exports, _bodyClass) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_bodyClass.default, {
    // classNames: ['village-senat-body-class'],
    store: Ember.inject.service('store'),
    results: null,

    model() {
      return Ember.get(this, 'store').findAll('yota-dec');
    },

    afterModel(model) {
      Ember.set(model.get('lastObject'), 'isLast', true);

      model.map((question, key) => {
        if (key === 0) {
          Ember.set(question, 'isActive', true);
        }
        return question;
      });
    },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});