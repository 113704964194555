define('frontend/router', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    // this.route('quiz', { path: '/:id' });

    this.route('village-boker', {
      path: '/250a7107-1a35-4447-bee3-2824cd28da91'
    });

    this.route('village-senat', {
      path: '/7eae72ce-5185-4329-ac83-6ddb0a5e4184'
    });

    this.route('village-ikea', {
      path: '/b1e3e879-8838-4ea3-a810-fa4808bd3793'
    });

    this.route('village-yota', {
      path: '/e4c52e6e-f112-46f5-9897-0b36817dd361'
    });

    this.route('village-advent', {
      path: '/ebbeb6e6-4775-44c7-b410-d909c192c27d'
    });

    this.route('village-advent-2020', {
      path: '/faad3573-86ed-5219-82a8-589b10758a45'
    });

    this.route('village-yota-tarif', {
      path: '/aff230f1-0da1-42de-8ffc-4415e7221168'
    });

    this.route('village-boxberry', {
      path: '/dae85fa9-a9a1-4f85-b5b8-59cb3a9cde3d'
    });

    this.route('village-mts', {
      path: '/5c29263f-8aa2-53c5-ab8b-2386edb2ad80'
    });

    this.route('village-sadko', {
      path: '/bf045089-f90a-51fb-af1d-5243565a0559'
    });

    // quiz for 800 agency
    this.route('village-nizhny', {
      path: '/af8640db-c961-521a-9f24-d2ee2b9e12dc'
    });

    this.route('village-yota-dec', {
      path: '/8f8d27d6-d01f-5d51-848d-74cd53e0129a'
    });

    // interactive map for 800 agency
    this.route('village-kremlin', {
      path: '/84c78c49-2c16-5ee5-ba20-dc6c6e75254e'
    });

    this.route('village-pizza', {
      path: '/f4af8008-29ba-534f-90bf-3bf3aa6f125b'
    });

    this.route('village-yota-mem', {
      path: '/abc71c54-5a81-56dc-b9a7-1dd21d44a70a'
    });

    this.route('village-pokra', {
      path: '/15032c77-f2ea-52db-8dfd-8f3029dcbc57'
    });

    this.route('village-ikea-wish', {
      path: '/c8b53822-d526-5ef7-b006-297b63ed5d49'
    });

    this.route('village-tele', {
      path: '/a6a23dbd-4867-5201-a6e4-818d759a0445'
    });

    this.route('village-advent-2021', {
      path: '/7202a166-56bc-5f28-bbd6-0be481fd64db'
    });

    this.route('village-history-details', {
      path: '/c85f9e41-d846-55d9-8117-2e1f2267094c'
    });

    this.route('village-yota-presents', {
      path: '/dde44452-0ca7-5e2d-84a2-3f0a5c9f4720'
    });

    this.route('page-not-found', { path: '*path' });
  });

  exports.default = Router;
});