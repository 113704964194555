define('frontend/components/boker-answers/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['boker-answers'],
    classNameBindings: ['isDisabled:disabled'],
    store: Ember.inject.service(),
    currentAnswer: null,
    answers: null,
    isDisabled: false,

    activeAnswers: Ember.computed('answers', function () {
      const currentAnswer = Ember.get(this, 'currentAnswer');
      const answers = Ember.get(this, 'answers');

      let activeAnswers = [currentAnswer, ...answers];

      activeAnswers = activeAnswers.uniqBy('name');
      activeAnswers = activeAnswers.slice(0, 4);
      activeAnswers.sort(() => Math.random() - 0.5);

      return activeAnswers;
    }),

    actions: {
      clickAnsewer(answer) {
        Ember.set(this, 'isDisabled', true);
        if (Ember.get(this, 'clickAnsewer')) {
          Ember.get(this, 'clickAnsewer')(answer);
        }
      }
    }
  });
});