define('frontend/components/yota-mem-desc/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isCorrect:is-correct'],

    slides: null,
    isCorrect: false,

    didInsertElement() {
      this._super(...arguments);

      setTimeout(() => {
        this._setHeight(document.documentElement.scrollHeight);
      }, 600);
    },

    _setHeight(height) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'set',
          data: {
            id: 'abc71c54-5a81-56dc-b9a7-1dd21d44a70a',
            height: `${height}px`
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});