define('frontend/controllers/village-yota-tarif', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    apps: Ember.computed.alias('model.apps'),
    sentences: Ember.computed.alias('model.sentences'),

    isResultScreen: false,

    userName: null,
    selectedMin: null,
    selectedGb: null,

    isSMSOnly: Ember.computed('selectedGb', function () {
      return parseInt(this.selectedGb) > 50;
    }),

    smsApp: Ember.computed('apps', function () {
      return this.apps.findBy('query_code', 'sms');
    }),

    isResultsDisabled: Ember.computed('userName', function () {
      return !this.userName || this.userName === '';
    }),

    minutes: Ember.computed(function () {
      let buff = [];

      for (let i = 0; i <= 2000; i++) {
        if (i % 10 === 0) {
          buff.push(i);
        }
      }

      return buff;
    }),
    gb: Ember.computed(function () {
      return [...Array(51).keys()];
    }),

    selectedApps: Ember.computed.filter('apps.@each.status', function (app) {
      return app.get('status');
    }),

    sentencesForApps: Ember.computed('selectedApps', 'sentences', function () {
      let sentences_for_apps = [];
      let isSmsSelected = this.selectedApps.findBy('id', this.smsApp.id) !== undefined;

      if (this.isSMSOnly && isSmsSelected) {
        sentences_for_apps = [this.sentences.findBy('id', this.smsApp.group_id).text];
      } else {
        this.sentences.forEach(element => {
          if (this.selectedApps.isAny('group_id', element.id)) {
            sentences_for_apps.push(element.get('text'));
          }
        });
      }

      return sentences_for_apps;
    }),

    urlQuery: Ember.computed('selectedApps', function () {
      let query_codes = this.selectedApps.map(elem => {
        return elem.get('query_code');
      });

      return `${query_codes.join(',')}`;
    }),

    linkToYota: Ember.computed('selectedMin', 'selectedGb', 'urlQuery', function () {
      return `https://www.yota.ru/voice?gb=${this.selectedGb}&min=${this.selectedMin}&app=${this.urlQuery}#myyota/collect-your`;
    }),

    resultText: Ember.computed('selectedMin', 'selectedGb', 'sentencesForApps', function () {
      return [this._getMinutesSentence(this.selectedMin), this._getGbSentence(this.selectedGb), ...this.sentencesForApps];
    }),

    actions: {
      setUserName(value) {
        Ember.set(this, 'userName', value);
      },

      setSelectedValue(type, value) {
        if (type === 'min') {
          Ember.set(this, 'selectedMin', value);
        } else {
          Ember.set(this, 'selectedGb', value);
        }
      },

      showResults() {
        Ember.set(this, 'isResultScreen', true);
      },

      getTarifInfo() {
        this._redirect(this.linkToYota);
      }
    },

    _redirect(url) {
      window.top.location = url;
    },

    _getMinutesSentence(value) {
      let sentence;

      switch (true) {
        case value >= 1500:
          sentence = 'Большой пакет минут – олдскул вариант для тех, кто очень «бизи», болтает часами с друзьями и держит в курсе своих дел всех родственников.';
          break;
        case value >= 500:
          sentence = 'Много звонков для долгих разговоров с родными и коллегами без переживаний о лишних затратах.';
          break;
        case value > 0:
          sentence = 'Адекватное количество минут для адекватных бесед – некоторые вопросики приходится обсуждать именно голосом.';
          break;
        default:
          sentence = 'Никакой болтовни, найдите меня в Facebook.';
          break;
      }

      return sentence;
    },
    _getGbSentence(value) {
      let sentence;

      switch (true) {
        case value > 50:
          sentence = 'Безлимитный интернет, чтобы пересматривать любимые сериальчики, ежедневно веселиться с друзьями в Zoom и готовить классные блюда по видеорецептам.';
          break;
        case value >= 25:
          sentence = 'Достаточное количество интернета чтобы не вылезать из соцсетей, записывать видосы в Tik Tok, а засыпая, смотреть YouTube.';
          break;
        case value >= 5:
          sentence = 'Постоянный доступ в интернет для тех, кто не имеет привычки сидеть в сети часами.';
          break;
        default:
          sentence = 'Информационная гигиена и свобода от потока новостей из интернета с возможностью добавить безлимитные мессенджеры и соцсети отдельно, вы все-таки не совсем аскет.';
          break;
      }

      return sentence;
    }
  });
});