define("frontend/storages/advent-days-2021", ["exports", "ember-local-storage/local/array"], function (exports, _array) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Storage = _array.default.extend();

  // Uncomment if you would like to set initialState
  Storage.reopenClass({
    initialState() {
      return [];
    }
  });

  exports.default = Storage;
});