define('frontend/controllers/village-yota-presents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    persons: Ember.computed.alias('model'),
    results: null,
    resultsSum: 0,

    budgetVariants: [{
      value: 130,
      desc: 'Это конечно виртуальная сумма. А в реальных рублях даже не за 130, а за 129 рублей Yota предоставит тариф с 16 Гб интернета - идеально для тех, кто совсем не пользуется звонками, зато ценит достоинства мессенджеров.'
    }, {
      value: 270,
      desc: 'Игра игрой, а в реальных рублях на эту сумму в Yota вы можете выбрать 103 минуты на разговоры и солидный пакет интернета в 20 Гб'
    }, {
      value: 520,
      desc: 'Это конечно виртуальная сумма. А в реальных рублях вы получили бы от Yota 597 минут для разговоров по работе и с близкими и целых 50 Гб интернета, которого хватит на то, чтобы смотреть фильмы без ограничений все каникулы и даже больше.'
    }, {
      value: 1104,
      desc: 'Ого! Да это же максимальная  игровая сумма, а в реальных рублях это была бы максимальная  конфигурация тарифа от Yota! 2000 минут и безлимитный интернет для тех, кто не мелочится, и готов всегда быть на связи.'
    }],

    gameBudget: Ember.computed('budgetVariants.[]', function () {
      const budgetVariants = Ember.get(this, 'budgetVariants');
      return budgetVariants[Math.floor(Math.random() * budgetVariants.length)];
    }),

    isEnded: Ember.computed('persons', function () {
      const persons = Ember.get(this, 'persons');
      let sumOfSelected = 0;

      persons.forEach(item => {
        const isSelected = Ember.get(item, 'selected') ? 1 : 0;
        sumOfSelected = sumOfSelected + isSelected;
      });

      return sumOfSelected === 5;
    }),

    isWin: Ember.computed('resultsSum', 'gameBudget', function () {
      const resultsSum = Ember.get(this, 'resultsSum');
      const gameBudget = Ember.get(this, 'gameBudget');

      return resultsSum <= gameBudget.value;
    }),

    actions: {
      setPersonPresent(personId, wish) {
        const person = Ember.get(this, 'persons').filter(item => item.id === personId);
        Ember.set(person[0], 'selected', wish);
        this.notifyPropertyChange('persons');
      },

      setResults() {
        if (!Ember.get(this, 'isEnded')) {
          return false;
        }

        const persons = Ember.get(this, 'persons');
        let wishes = [];
        let wishesPriceSum = 0;

        persons.forEach(item => {
          const selectedWish = Ember.get(item, 'selected');
          wishes.push(selectedWish);

          wishesPriceSum = wishesPriceSum + Number(selectedWish.price);
        });

        Ember.set(this, 'results', wishes);
        Ember.set(this, 'resultsSum', wishesPriceSum);
      },

      startNewGame() {
        window.location.reload();
      }
    }
  });
});