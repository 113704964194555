define('frontend/controllers/village-boxberry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    questions: Ember.computed.alias('model'),
    results: null,
    answeredCounter: 0,
    isRequiresIframeScrollFix: false,

    currentQuestionNumber: Ember.computed('answeredCounter', function () {
      return Ember.get(this, 'answeredCounter') + 1;
    }),

    actions: {
      nextQuestion(question) {
        let questions = Ember.get(this, 'questions');

        questions.forEach(element => {
          if (question.id === element.id) {
            Ember.set(element, 'isActive', false);
            Ember.set(element, 'isAnswered', true);

            let nextQuestion = questions.findBy('isAnswered', false);

            if (nextQuestion !== undefined) {
              Ember.set(nextQuestion, 'isActive', true);

              this._scrollToQuestion();
            }
          }
        });

        let currentCounter = Ember.get(this, 'answeredCounter');

        if (!currentCounter) {
          Ember.set(this, 'answeredCounter', 1);
        } else {
          Ember.set(this, 'answeredCounter', currentCounter + 1);
        }

        Ember.set(this, 'questions', questions);
      },

      setResults(answerId) {
        let results = Ember.isEmpty(Ember.get(this, 'results')) ? [] : Ember.get(this, 'results');
        results.push(answerId);
        Ember.set(this, 'results', results);
      },

      endQuiz(question) {
        Ember.set(question, 'isActive', false);
        Ember.set(question, 'isAnswered', true);

        Ember.set(this, 'isEnded', true);
      }
    },

    _scrollToQuestion() {
      const wrapperElement = document.getElementById('boxberryWrapperElement');
      const isRequiresIframeScrollFix = this._requiresIframeScrollFix();

      // set this flag for result view
      Ember.set(this, 'isRequiresIframeScrollFix', isRequiresIframeScrollFix);

      if (isRequiresIframeScrollFix) {
        const frameTopPadding = 0;
        // Tell IFrame parent to do the scrolling. If this is not a test environment, replace "*" with the parent domain.
        window.parent.postMessage(frameTopPadding, 'https://www.the-village.ru/');
      } else {
        wrapperElement.scrollIntoView();
      }
    },

    _requiresIframeScrollFix() {
      try {
        // Check for safari
        var is_safari = navigator.userAgent.indexOf('Safari') > -1;
        // Chrome has Safari in the user agent so we need to filter (https://stackoverflow.com/a/7768006/1502448)
        var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
        if (is_chrome && is_safari) {
          is_safari = false;
        }

        // If we need to narrow this down even further we can use a more robust browser detection (https://stackoverflow.com/questions/5916900)
        // Problematic browsers can be adjusted here.
        if (is_safari && this._inIframe() && (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Safari/i))) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error('Error while try to detect Iframe scroll fix', e);
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});