define('frontend/models/tele', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    name: attr('string'),
    photo: attr('string'),
    question: attr('string'),
    answer: attr('string'),
    persons: attr(),

    isActive: attr('boolean', {
      defaultValue: false
    }),

    isLast: attr('boolean', {
      defaultValue: false
    }),

    isAnswered: attr('boolean', {
      defaultValue: false
    })
  });
});