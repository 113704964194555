define('frontend/components/village-nizhny-q/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['question-boker', 'question-nizhny'],
    classNameBindings: ['isActive:active'],
    store: Ember.inject.service(),
    question: null,

    answers: Ember.computed('questions', function () {
      return Ember.get(this, 'questions.answers');
    }),

    isActive: Ember.computed('question.isActive', function () {
      console.error('isActive', Ember.get(this, 'question.isActive'));
      return Ember.get(this, 'question.isActive');
    }),

    isAnswered: false,
    isCorrectAnswer: false,
    selectedAnswer: null,

    showNext: false,

    actions: {
      selectAnswer(answer) {
        Ember.set(this, 'isAnswered', true);
        Ember.set(this, 'selectedAnswer', answer);
        if (answer.id === Ember.get(this, 'question.correctAnswer')) {
          Ember.get(this, 'setResults')(answer.id);
          Ember.set(this, 'isCorrectAnswer', true);
        } else {
          Ember.set(this, 'isCorrectAnswer', false);
        }

        Ember.set(this, 'showNext', true);

        // setTimeout(() => {
        //   let questionHeight = this.$().height();
        //   this.nextQuestion(get(this, 'question'), questionHeight);
        // }, 2000);

        // this.nextQuestion(get(this, 'question'));
      },

      nextQuestion(question) {
        Ember.get(this, 'nextQuestion')(question);
      },

      endQuiz(question) {
        Ember.get(this, 'endQuiz')(question);
      }
    }
  });
});