define('frontend/models/yota-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    index: attr('number'),
    questionText: attr('string'),
    afterCanReturnTo: attr('number'),
    answers: attr(),

    // POSIBILITY for return to the question
    isReturn: attr('boolean', {
      defaultValue: false
    }),

    // ACTIVATION return action
    isCanReturn: attr('boolean', {
      defaultValue: false
    }),

    isActive: attr('boolean', {
      defaultValue: false
    }),

    isAnswered: attr('boolean', {
      defaultValue: false
    }),

    isLast: attr('boolean', {
      defaultValue: false
    }),

    refreshToken: attr('string')
  });
});