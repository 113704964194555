define('frontend/components/sadko-answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['sadko__answer'],
    classNameBindings: ['className'],
    className: 'sadko__q-1__answer',

    group: null,
    answer: null,
    correctAnswers: [],
    isAnswered: false,

    isCorrectAnswer: Ember.computed('correctAnswers', function () {
      return this.correctAnswers.indexOf(this.answer.id) !== -1;
    }),

    actions: {
      clickAnsewer() {
        if (Ember.get(this, 'clickAnsewer')) {
          Ember.get(this, 'clickAnsewer')(Ember.get(this, 'group'), Ember.get(this, 'answer'));
        }
      }
    }
  });
});