define("frontend/components/advent-day/component", ["exports", "ember-local-storage", "date-fns"], function (exports, _emberLocalStorage, _dateFns) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["advent-day", "col-day"],
    adventDays: (0, _emberLocalStorage.storageFor)("advent-days"),
    store: Ember.inject.service(),

    day: null,
    currentDay: null,

    itemDate: Ember.computed("day.date", function () {
      return (0, _dateFns.parseISO)(`${Ember.get(this, "day.date")}T09:00:00+0300`);
    }),

    isLocked: Ember.computed("itemDate", "currentDay", function () {
      return !(0, _dateFns.isAfter)(Ember.get(this, "currentDay"), Ember.get(this, "itemDate"));
    }),

    isToday: Ember.computed("itemDate", "currentDay", function () {
      return (0, _dateFns.isSameDay)(Ember.get(this, "itemDate"), Ember.get(this, "currentDay"));
    }),

    isOpen: Ember.computed("adventDays.[]", "day.id", function () {
      return Ember.get(this, "adventDays").includes(this.get("day.id"));
    }),

    actions: {
      clickDay(day) {
        if (!Ember.get(this, "isLocked") && !Ember.get(this, "isOpen")) {
          // Save to localStorage
          this.get("adventDays").addObject(day.id);

          setTimeout(() => {
            this._redirect(day.link);
          }, 500);
        }
      },

      openLink(day) {
        this._redirect(day.link);
      }
    },

    _redirect(url) {
      window.top.location = url;
    }
  });
});