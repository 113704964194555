define('frontend/controllers/village-kremlin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    points: Ember.computed.alias('model'),
    currentPoint: null,

    actions: {
      clickOnPoint(id) {
        const currentPoint = Ember.get(this, 'points').filter(item => item.id === id)[0];
        Ember.set(this, 'currentPoint', currentPoint);
      }
    }
  });
});