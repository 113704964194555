define('frontend/components/yota-digit-slider-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['yota-digit-slider-input'],
    classNameBindings: ['isHideInput:is-hide'],

    type: null,
    value: null,
    isHideInput: false,
    isFocused: false,

    click() {
      this.setActive();

      Ember.set(this, 'isFocused', true);

      this.$('[autofocus]').focus();
    },

    focusOut() {
      this._setValue();
    },

    // didInsertElement() {
    //   this._super(...arguments);
    // },

    actions: {
      updateValue(value) {
        const new_value = value !== '' ? value.replace(/[^0-9]/g, '') : null;
        Ember.set(this, 'value', new_value);
      },

      enterValue() {
        this._setValue();
      }
    },

    _setValue() {
      Ember.set(this, 'isFocused', false);
      const val = this.value ? parseInt(this.value) : 0;
      this.setValue(val);
    }
  });
});