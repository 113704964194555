define('frontend/serializers/question-senat', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { RESTSerializer, EmbeddedRecordsMixin } = _emberData.default;

  exports.default = RESTSerializer.extend(EmbeddedRecordsMixin, {});
});