define('frontend/routes/village-yota-mem', ['exports', 'ember-body-class/mixins/body-class'], function (exports, _bodyClass) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_bodyClass.default, {
    classNames: ['village-yota-mem-body-class'],
    store: Ember.inject.service('store'),

    model() {
      return Ember.RSVP.hash({
        mems: this.store.findAll('yota-mem'),
        answers: this.store.findAll('yota-mem-answer')
      });
    },

    afterModel(model) {
      function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      const randomMemStartIndex = getRandomInt(0, model.mems.length - 1);
      model.mems.map((mem, key) => {
        if (key === randomMemStartIndex) {
          Ember.set(mem, 'isSelected', true);
        }
        return mem;
      });

      const randomAnswerStartIndex = getRandomInt(0, model.answers.length - 1);
      model.answers.map((answer, key) => {
        if (key === randomAnswerStartIndex) {
          Ember.set(answer, 'isSelected', true);
        }
        return answer;
      });
    },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});