define('frontend/components/yota-app/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['yota-app_element'],
    classNameBindings: ['isActive:active'],
    store: Ember.inject.service(),
    app: null,

    isActive: Ember.computed('app.status', function () {
      return this.app.status;
    }),

    backgroundImageStyle: Ember.computed('app.{status,img_url,img_active_url}', function () {
      let img_url = this.app.status ? this.app.img_active_url : this.app.img_url;
      return Ember.String.htmlSafe(`background-image: url(${img_url});`);
    }),

    click() {
      this.toggleProperty('app.status');
    },

    actions: {}
  });
});