define('frontend/components/yota-results/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['yota-results'],
    store: Ember.inject.service(),
    reason: null,

    isOne: Ember.computed('reason', function () {
      return Ember.get(this, 'reason') === 'balance';
    }),
    isTwo: Ember.computed('reason', function () {
      return Ember.get(this, 'reason') === 'psyho';
    }),
    isThree: Ember.computed('reason', function () {
      return Ember.get(this, 'reason') === 'end';
    })
  });
});