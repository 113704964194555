define('frontend/controllers/village-yota-dec', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),

    resultTable: [[{
      isPlaced: false,
      className: 'cell_0'
    }, {
      isPlaced: false,
      className: 'cell_0'
    }, {
      isPlaced: true,
      className: 'cell_10'
    }, {
      isPlaced: false,
      className: 'cell_0'
    }, {
      isPlaced: false,
      className: 'cell_0'
    }], [{
      isPlaced: false,
      className: 'cell_0'
    }, {
      isPlaced: true,
      className: 'cell_9'
    }, {
      isPlaced: true,
      className: 'cell_5'
    }, {
      isPlaced: true,
      className: 'cell_6'
    }, {
      isPlaced: false,
      className: 'cell_0'
    }], [{
      isPlaced: true,
      className: 'cell_13'
    }, {
      isPlaced: true,
      className: 'cell_4'
    }, {
      isPlaced: true,
      className: 'cell_1'
    }, {
      isPlaced: true,
      className: 'cell_2'
    }, {
      isPlaced: true,
      className: 'cell_11'
    }], [{
      isPlaced: false,
      className: 'cell_0'
    }, {
      isPlaced: true,
      className: 'cell_8'
    }, {
      isPlaced: true,
      className: 'cell_3'
    }, {
      isPlaced: true,
      className: 'cell_7'
    }, {
      isPlaced: false,
      className: 'cell_0'
    }], [{
      isPlaced: false,
      className: 'cell_0'
    }, {
      isPlaced: false,
      className: 'cell_0'
    }, {
      isPlaced: true,
      className: 'cell_12'
    }, {
      isPlaced: false,
      className: 'cell_0'
    }, {
      isPlaced: false,
      className: 'cell_0'
    }]],
    questions: Ember.computed.alias('model'),
    // results: null,
    answeredCounter: null,

    isEnded: Ember.computed('answeredCounter', function () {
      return Ember.get(this, 'answeredCounter') === 13 ? true : false;
    }),

    actions: {
      nextQuestion(question) {
        let questions = Ember.get(this, 'questions');

        questions.forEach(element => {
          if (question.id === element.id) {
            Ember.set(element, 'isActive', false);
            Ember.set(element, 'isAnswered', true);

            let nextQuestion = questions.findBy('isAnswered', false);

            if (nextQuestion !== undefined) {
              Ember.set(nextQuestion, 'isActive', true);
            }
          }
        });

        let currentCounter = Ember.get(this, 'answeredCounter');

        if (!currentCounter) {
          Ember.set(this, 'answeredCounter', 1);
        } else {
          Ember.set(this, 'answeredCounter', currentCounter + 1);
        }

        Ember.set(this, 'questions', questions); // ?
      },

      setResults(question) {
        let results = Ember.isEmpty(Ember.get(this, 'results')) ? [] : Ember.get(this, 'results');
        results.push(question);

        Ember.set(this, 'results', results);

        this.notifyPropertyChange('results');
      },

      endQuiz(question) {
        Ember.set(question, 'isActive', false);
        Ember.set(question, 'isAnswered', true);

        Ember.set(this, 'isEnded', true);
      }
    }
  });
});