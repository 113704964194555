define('frontend/routes/village-yota-tarif', ['exports', 'ember-body-class/mixins/body-class'], function (exports, _bodyClass) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_bodyClass.default, {
    classNames: ['village-yota-tarif'],
    store: Ember.inject.service('store'),

    model() {
      return Ember.RSVP.hash({
        apps: this.store.findAll('yota-app'),
        sentences: this.store.findAll('yota-sentence')
      });
    },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});