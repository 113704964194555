define('frontend/models/yota-mem-answer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    img: attr('string'),
    text: attr('string'),

    isActive: attr('boolean', {
      defaultValue: true
    }),
    isSelected: attr('boolean', {
      defaultValue: false
    })
  });
});