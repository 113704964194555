define('frontend/components/boker-results/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['boker-results'],
    store: Ember.inject.service(),
    results: null,

    didReceiveAttrs() {
      this._super(...arguments);

      // trick for each-in helper
      this.notifyPropertyChange('results');
    }
  });
});