define('frontend/routes/village-yota', ['exports', 'ember-body-class/mixins/body-class', 'date-fns'], function (exports, _bodyClass, _dateFns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_bodyClass.default, {
    classNames: ['village-yota-body'],
    store: Ember.inject.service('store'),

    queryParams: {
      startAgain: {
        refreshModel: true
      }
    },

    model() {
      return Ember.get(this, 'store').findAll('yota-question');
    },

    afterModel(model) {
      Ember.set(model.get('lastObject'), 'isLast', true);

      model.map((question, key) => {
        Ember.set(question, 'isActive', false);
        Ember.set(question, 'isCanReturn', false);
        Ember.set(question, 'isAnswered', false);

        if (key === 0) {
          Ember.set(question, 'isActive', true);
        }
        return question;
      });

      Ember.set(this.controllerFor('village-yota'), 'balanceBattery', Ember.get(this, 'store').createRecord('yota-battery', {
        title: 'Баланс'
      }));
      Ember.set(this.controllerFor('village-yota'), 'psyhoBattery', Ember.get(this, 'store').createRecord('yota-battery', {
        title: 'Нервы'
      }));
    },

    actions: {
      startAgain() {
        Ember.set(this.controllerFor('village-yota'), 'isEnded', false);
        Ember.set(this.controllerFor('village-yota'), 'isStarted', false);
        Ember.set(this.controllerFor('village-yota'), 'reason', null);

        this.transitionTo({ queryParams: { startAgain: (0, _dateFns.getTime)(new Date()) } });
      },

      error: function () {
        return true;
      }
    }
  });
});