define('frontend/models/village-2020-advent', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    day: attr('string'),
    date: attr('string'),
    link: attr('string'),
    front: attr('string'),
    frontMobile: attr('string'),
    back: attr('string')
  });
});