define('frontend/models/yota-battery', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    title: attr('string'),

    pills: attr({
      defaultValue() {
        return Ember.A([0, 1, 2, 3]);
      }
    }),

    isEmpty: attr('boolean', {
      defaultValue: false
    })
  });
});