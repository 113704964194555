define('frontend/components/boxberry-results/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['boxberry-results'],
    store: Ember.inject.service(),
    results: null,
    isRequiresIframeScrollFix: false,

    didInsertElement() {
      const resultTitleElement = document.getElementById('boxberryWrapperElement');

      if (this.isRequiresIframeScrollFix) {
        const frameTopPadding = 0;
        // Tell IFrame parent to do the scrolling. If this is not a test environment, replace "*" with the parent domain.
        window.parent.postMessage(frameTopPadding, 'https://www.the-village.ru/');
      } else {
        resultTitleElement.scrollIntoView();
      }
    },

    correctAmount: Ember.computed('results', function () {
      let res = Ember.get(this, 'results') || [];
      return res.length;
    }),

    isLevelOne: Ember.computed('correctAmount', function () {
      return Ember.get(this, 'correctAmount') < 3;
    }),
    isLevelTwo: Ember.computed('correctAmount', function () {
      return Ember.get(this, 'correctAmount') === 3;
    }),
    isLevelThree: Ember.computed('correctAmount', function () {
      return Ember.get(this, 'correctAmount') > 3 && Ember.get(this, 'correctAmount') < 7;
    }),
    isLevelFour: Ember.computed('correctAmount', function () {
      return Ember.get(this, 'correctAmount') >= 7;
    }),

    actions: {
      goToTop() {
        window.parent.postMessage('gotothepost', 'https://www.the-village.ru/');
      }
    }
  });
});