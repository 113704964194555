define('frontend/serializers/poll', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { RESTSerializer, EmbeddedRecordsMixin } = _emberData.default;

  exports.default = RESTSerializer.extend(EmbeddedRecordsMixin, {
    normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      const newPayload = {
        poll: payload
      };

      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });
});