define('frontend/components/ikea-question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['question-boker'],
    classNameBindings: ['question.isActive:active'],
    store: Ember.inject.service(),
    question: null,

    answers: Ember.computed('question.answers', function () {
      return Ember.get(this, 'question.answers');
    }),

    isAnswered: false,
    isCorrectAnswer: false,
    selectedAnswer: null,

    showNext: false,

    actions: {
      selectAnswer(answer) {
        Ember.set(this, 'isAnswered', true);
        Ember.set(this, 'selectedAnswer', answer);

        Ember.get(this, 'question.answers').forEach(element => {
          return element.id === answer.id ? Ember.set(element, 'isAnswered', true) : Ember.set(element, 'isDisable', true);
        });

        Ember.get(this, 'setResults')(answer.index);

        Ember.set(this, 'showNext', true);
      },

      nextQuestion(question) {
        Ember.get(this, 'nextQuestion')(question);
      },

      endQuiz(question) {
        Ember.get(this, 'endQuiz')(question);
      }
    }
  });
});