define('frontend/adapters/village-2020-advent', ['exports', 'frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindAll() {
      return '/data/advent-2020.json';
    }
  });
});