define('frontend/models/kremlin-point', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    tooltipTitle: attr('string'),
    tooltipText: attr('string'),

    value: attr('string'),
    title: attr('string'),
    img: attr('string'),
    imgs: attr(),
    text: attr('string'),

    isActive: attr('boolean', {
      defaultValue: false
    }),

    isAnswered: attr('boolean', {
      defaultValue: false
    }),

    isLast: attr('boolean', {
      defaultValue: false
    })
  });
});