define('frontend/controllers/village-nizhny', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    questions: Ember.computed.alias('model'),
    results: null,
    answeredCounter: null,
    currentQuestion: 1,

    isEnded: Ember.computed('answeredCounter', function () {
      return Ember.get(this, 'answeredCounter') === 12 ? true : false;
    }),

    actions: {
      nextQuestion(question) {
        let questions = Ember.get(this, 'questions');

        questions.forEach(element => {
          if (question.id === element.id) {
            Ember.set(element, 'isActive', false);
            Ember.set(element, 'isAnswered', true);

            let nextQuestion = questions.findBy('isAnswered', false);

            if (nextQuestion !== undefined) {
              console.error('set isActive');
              Ember.set(nextQuestion, 'isActive', true);
            }
          }
        });

        let currentCounter = Ember.get(this, 'answeredCounter');

        if (!currentCounter) {
          Ember.set(this, 'answeredCounter', 1);
        } else {
          Ember.set(this, 'answeredCounter', currentCounter + 1);
        }

        Ember.set(this, 'currentQuestion', Ember.get(this, 'currentQuestion') + 1);

        Ember.set(this, 'questions', questions); // ?
      },

      setResults(answerId) {
        let results = Ember.isEmpty(Ember.get(this, 'results')) ? [] : Ember.get(this, 'results');
        results.push(answerId);
        Ember.set(this, 'results', results);
      },

      endQuiz(question) {
        Ember.set(question, 'isActive', false);
        Ember.set(question, 'isAnswered', true);

        Ember.set(this, 'isEnded', true);
      }
    }
  });
});