define('frontend/components/boxberry-answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['boker-answers'],
    classNameBindings: ['isDisabled:disabled'],
    store: Ember.inject.service(),

    answer: null,
    isAnswered: false,
    isCorrectAnswer: false,

    isDisabled: Ember.computed('answer.isDisable', function () {
      return Ember.get(this, 'answer.isDisable');
    }),

    actions: {
      clickAnsewer() {
        if (Ember.get(this, 'clickAnsewer') && !Ember.get(this, 'isDisabled')) {
          Ember.get(this, 'clickAnsewer')(Ember.get(this, 'answer'));
        }
      }
    }
  });
});