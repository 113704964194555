define('frontend/models/question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    questionText: attr('string'),
    questionKey: attr('string'),
    description: attr('string'),
    answer: attr(),

    isActive: attr('boolean', {
      defaultValue: false
    }),

    isAnswered: attr('boolean', {
      defaultValue: false
    }),

    isLast: attr('boolean', {
      defaultValue: false
    })
  });
});