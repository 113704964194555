define('frontend/routes/village-boker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    store: Ember.inject.service('store'),

    model() {
      return Ember.get(this, 'store').findAll('question');
    },

    afterModel(model) {
      Ember.set(model.get('lastObject'), 'isLast', true);

      model.map((question, key) => {
        if (key === 0) {
          Ember.set(question, 'isActive', true);
        }
        return question;
      });
    },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});