define('frontend/components/sadko-result/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['sadko__desc'],
    classNameBindings: ['isEnded:visible'],

    personId: null,
    description: null,

    didInsertElement() {
      this._super(...arguments);

      setTimeout(() => {
        this._setHeight(document.documentElement.scrollHeight);
      }, 600);
    },

    _setHeight(height) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'set',
          data: {
            id: this.personId,
            height: `${height}px`
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});