define('frontend/helpers/and', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function ([...args]) {
    return [...args].every(el => {
      return el;
    });
  });
});