define('frontend/controllers/village-boker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    questions: Ember.computed.alias('model'),
    results: null,
    isEnded: false,

    answers: Ember.computed('questions', function () {
      return Ember.get(this, 'questions').map(question => {
        return question.answer;
      });
    }),

    actions: {
      nextQuestion(question) {
        let questions = Ember.get(this, 'questions');

        questions.forEach(element => {
          if (question.id === element.id) {
            Ember.set(element, 'isActive', false);
            Ember.set(element, 'isAnswered', true);

            let nextQuestion = questions.findBy('isAnswered', false);
            Ember.set(nextQuestion, 'isActive', true);
          }
        });

        Ember.set(this, 'questions', questions);
      },

      setResults(answer, questionKey) {
        const name = answer.name;
        let results = Ember.isEmpty(Ember.get(this, 'results')) ? {} : Ember.get(this, 'results');

        const nameInResults = Object.keys(results).find(item => item === name);

        if (nameInResults !== undefined) {
          results[name] = [...results[name], questionKey];
        } else {
          results[name] = [questionKey];
        }

        Ember.set(this, 'results', results);

        // trick for each-in helper
        this.notifyPropertyChange('results');
      },

      endQuiz(question) {
        Ember.set(question, 'isActive', false);
        Ember.set(question, 'isAnswered', true);

        Ember.set(this, 'isEnded', true);
      }
    }
  });
});