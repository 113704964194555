define('frontend/controllers/village-sadko', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['person'],
    person: null,
    answers: null,
    isEnded: false,
    q_1isAnswered: false,
    q_2isAnswered: false,
    q_3isAnswered: false,

    currentPerson: Ember.computed('person', 'model', function () {
      let person = this.person;
      let data = this.model;

      if (person) {
        const filtered = data.filterBy('id', person);
        return filtered[0];
      } else {
        console.error('error with get person: "person" is not defined');
        return data[0];
      }
    }),

    actions: {
      selectAnswer(group, answer) {
        const answers = this.answers || [];
        answers.push(answer);

        Ember.set(this, 'answers', answers);

        switch (group) {
          case 'q_1':
            Ember.set(this, 'q_1isAnswered', true);
            break;
          case 'q_2':
            Ember.set(this, 'q_2isAnswered', true);
            break;
          case 'q_3':
            Ember.set(this, 'q_3isAnswered', true);
            break;
          default:
            break;
        }

        if (answers.length === 3) {
          Ember.set(this, 'isEnded', true);

          setTimeout(() => {
            this._setHeight(document.documentElement.scrollHeight);
          }, 200);
        }
      }
    },

    _setHeight(height) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'set',
          data: {
            id: this.person,
            height: `${height}px`
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});