define('frontend/routes/village-tele', ['exports', 'ember-body-class/mixins/body-class'], function (exports, _bodyClass) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_bodyClass.default, {
    classNames: ['village-tele-body-class'],
    store: Ember.inject.service('store'),

    model() {
      return Ember.get(this, 'store').findAll('tele');
    },

    // afterModel(model) {
    //   // set(model.get('lastObject'), 'isLast', true);
    // },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});