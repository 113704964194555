define('frontend/components/village-nizhny-results/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['boker-results'],
    store: Ember.inject.service(),
    results: null,

    correctAmount: Ember.computed('results', function () {
      let res = Ember.get(this, 'results') || [];
      return res.length;
    }),

    isLow: Ember.computed('correctAmount', function () {
      return Ember.get(this, 'correctAmount') <= 3;
    }),
    isMedium: Ember.computed('correctAmount', function () {
      return Ember.get(this, 'correctAmount') > 3 && Ember.get(this, 'correctAmount') < 9;
    }),
    isHigh: Ember.computed('correctAmount', function () {
      return Ember.get(this, 'correctAmount') >= 9;
    })
  });
});