define("frontend/routes/village-advent", ["exports", "ember-body-class/mixins/body-class", "fetch"], function (exports, _bodyClass, _fetch) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_bodyClass.default, {
    classNames: ["village-advent-body"],
    store: Ember.inject.service("store"),

    model() {
      return Ember.RSVP.hash({
        days: Ember.get(this, "store").findAll("village-advent"),
        currentDay: (0, _fetch.default)("https://worldtimeapi.org/api/timezone/Europe/Moscow").then(response => {
          return response.json();
        }).then(response => {
          return new Date(response.datetime);
        }).catch(() => {
          return new Date();
        })
      });
    },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});