define('frontend/serializers/question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { RESTSerializer, EmbeddedRecordsMixin } = _emberData.default;

  exports.default = RESTSerializer.extend(EmbeddedRecordsMixin, {
    // attrs: {
    //   answers: { embedded: 'always' },
    // },
    // normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
    //   let newPayload = [];
    //   const answers = payload.questions.map(value => {
    //     return value.answer;
    //   });
    //   const answersIds = payload.questions.map(value => {
    //     return value.answer.id;
    //   });
    //   console.error('answersIds', answers);
    //   newPayload.questions = payload.questions;
    //   // newPayload.questions.answers = answersIds;
    //   newPayload.questions.answers = answers;
    //   // newPayload.answers = answers;
    //   // newPayload.questions.answers = answers;
    //   console.error('newPayload', newPayload);
    //   return this._super(store, primaryModelClass, newPayload, id, requestType);
    // },
  });
});