define('frontend/controllers/village-yota-mem', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    mems: Ember.computed.alias('model.mems'),
    answers: Ember.computed.alias('model.answers'),

    isDone: false,
    isError: false,
    isCorrect: false,
    text: null,

    activeMems: Ember.computed.filter('mems.[]', function (mem) {
      return mem.get('isActive');
    }),
    activeAnswers: Ember.computed.filter('answers.[]', function (answer) {
      return answer.get('isActive');
    }),

    currentMem: Ember.computed.filterBy('activeMems', 'isSelected', true),
    currentAnswer: Ember.computed.filterBy('activeAnswers', 'isSelected', true),

    actions: {
      finish() {
        Ember.set(this, 'isDone', true);
      },

      checkSelectedMatch() {
        const currentMem = Ember.get(this, 'currentMem')[0];
        const currentAnswer = Ember.get(this, 'currentAnswer')[0];

        if (currentMem.correctAnswerId === currentAnswer.id) {
          Ember.set(this, 'isCorrect', true);
          Ember.set(this, 'text', currentAnswer.text);
        } else {
          Ember.set(this, 'isError', true);

          setTimeout(() => {
            Ember.set(this, 'isError', false);
          }, 2000);
        }
      },

      nextRound() {
        const currentMem = Ember.get(this, 'currentMem')[0];
        const currentAnswer = Ember.get(this, 'currentAnswer')[0];

        Ember.set(this, 'isCorrect', false);

        // remove Mem
        this._setFalse(Ember.get(this, 'mems'), currentMem.id);
        this.notifyPropertyChange('activeMems');

        const randomMemStartIndex = this._getRandomInt(0, Ember.get(this, 'activeMems').length - 1);
        const memToSelect = Ember.get(this, 'activeMems')[randomMemStartIndex];

        if (memToSelect) {
          Ember.set(memToSelect, 'isSelected', true);

          // remove Answer
          this._setFalse(Ember.get(this, 'answers'), currentAnswer.id);
          this.notifyPropertyChange('activeAnswers');

          const randomAnswerStartIndex = this._getRandomInt(0, Ember.get(this, 'activeAnswers').length - 1);
          const answerToSelect = Ember.get(this, 'activeAnswers')[randomAnswerStartIndex];

          Ember.set(answerToSelect, 'isSelected', true);
        } else {
          Ember.set(this, 'isDone', true);
        }
      }
    },

    _setFalse(data, id) {
      data.forEach((element, index) => {
        if (element.id === id) {
          Ember.set(element, 'isActive', false);
        }
      });
    },

    _getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  });
});