define('frontend/routes/village-advent-2020', ['exports', 'ember-body-class/mixins/body-class', 'axios'], function (exports, _bodyClass, _axios) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_bodyClass.default, {
    classNames: ['village-advent-2020-body'],
    store: Ember.inject.service('store'),

    model() {
      return Ember.RSVP.hash({
        days: Ember.get(this, 'store').findAll('village-2020-advent'),
        currentDay: _axios.default.get('https://worldtimeapi.org/api/timezone/Europe/Moscow').then(response => {
          return response.json();
        }).then(response => {
          return new Date(response.datetime);
        }).catch(() => {
          return new Date();
        })
      });
    },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});