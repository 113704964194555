define('frontend/components/tele-question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['tele-question'],
    classNameBindings: ['question.isActive:active'],
    store: Ember.inject.service(),
    question: null,
    isAnswered: false,

    leftCol: Ember.computed('question.persons', function () {
      return Ember.get(this, 'question.persons')[0];
    }),

    rightCol: Ember.computed('question.persons', function () {
      return Ember.get(this, 'question.persons')[1];
    }),

    isSelected: Ember.computed('leftCol.isSelected', 'rightCol.isSelected', function () {
      return Ember.get(this, 'leftCol.isSelected') && Ember.get(this, 'rightCol.isSelected');
    }),

    didInsertElement() {
      this._super(...arguments);

      setTimeout(() => {
        this._setHeight(document.documentElement.scrollHeight);
      }, 1000);
    },

    _setHeight(height) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'set',
          data: {
            id: 'a6a23dbd-4867-5201-a6e4-818d759a0445',
            height: `${height}px`
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },

    actions: {
      selectLeftCol(element) {
        Ember.get(this, 'leftCol.vars').forEach(item => {
          Ember.set(item, 'isSelected', false);
        });

        Ember.set(element, 'isSelected', true);
        Ember.set(this, 'leftCol.isSelected', true);
      },
      selectRightCol(element) {
        Ember.get(this, 'rightCol.vars').forEach(item => {
          Ember.set(item, 'isSelected', false);
        });

        Ember.set(element, 'isSelected', true);
        Ember.set(this, 'rightCol.isSelected', true);
      },

      showResult() {
        Ember.set(this, 'isAnswered', true);
      },

      nextQuestion(question) {
        Ember.get(this, 'nextQuestion')(question);
      },

      endQuiz(question) {
        Ember.get(this, 'endQuiz')(question);
      }
    }
  });
});