define('frontend/components/kremlin-map/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    points: null,
    tooltipTitle: null,
    tooltipText: null,

    didInsertElement() {
      this._super(...arguments);

      // setTimeout(() => {
      //   this._setResults();
      // }, 600);

      let pointCircles = document.querySelectorAll('.st16');
      // let pointCircles = document.querySelectorAll('.st13, .st12');

      for (var i = 0; i < pointCircles.length; i++) {
        pointCircles[i].addEventListener('mouseover', this._showTooltip.bind(this), false);
        pointCircles[i].addEventListener('mouseout', this._hideTooltip.bind(this), false);
      }
    },

    actions: {
      clickOnPoint(id) {
        if (Ember.get(this, 'clickOnPoint')) {
          Ember.get(this, 'clickOnPoint')(id);

          setTimeout(() => {
            this._setHeight(document.body.clientHeight);
            // this._setHeight(document.documentElement.scrollHeight);
          }, 600);
        }
      }
    },

    _showTooltip(e) {
      const tooltip = document.querySelectorAll('.kp-tooltip')[0];
      const currentPoint = Ember.get(this, 'points').filter(item => item.value === e.target.textContent)[0];
      // const currentPoint = get(this, 'points').filter((item) => item.id === e.target.getAttribute('data-id'))[0];

      const tooltipText = Ember.get(currentPoint, 'tooltipText');
      const tooltipTitle = !tooltipText ? Ember.get(currentPoint, 'title') : null;

      Ember.set(this, 'tooltipTitle', tooltipTitle);
      Ember.set(this, 'tooltipText', Ember.get(currentPoint, 'tooltipText'));

      tooltip.style.opacity = '1';
      tooltip.style.left = e.pageX + tooltip.clientWidth + 10 < document.body.clientWidth ? e.pageX + 10 + 'px' : document.body.clientWidth + 5 - tooltip.clientWidth + 'px';
      tooltip.style.top = e.pageY + tooltip.clientHeight + 10 < document.body.clientHeight ? e.pageY + 10 + 'px' : document.body.clientHeight + 5 - tooltip.clientHeight + 'px';
    },

    _hideTooltip(e) {
      const tooltip = document.querySelectorAll('.kp-tooltip')[0];
      tooltip.style.opacity = '0';
      tooltip.style.left = '-9999px';
      tooltip.style.top = '-9999px';
    },

    _setHeight(height) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'set',
          data: {
            id: '84c78c49-2c16-5ee5-ba20-dc6c6e75254e',
            height: `${height}px`
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});