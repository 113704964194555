define('frontend/controllers/village-yota', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    questions: Ember.computed.alias('model'),
    isStarted: false,
    isEnded: false,
    reason: null,

    lastActiveQuestion: null,

    isMobile: Ember.computed(function () {
      return window.isMobile.phone;
    }),

    balanceBattery: null,
    psyhoBattery: null,

    batteryObserver: Ember.observer('balanceBattery.pills', 'psyhoBattery.pills', function () {
      const balanceBatteryPills = Ember.get(this, 'balanceBattery.pills'),
            psyhoBatteryPills = Ember.get(this, 'psyhoBattery.pills');

      if (Ember.get(this, 'isStarted')) {
        if (Ember.isEmpty(balanceBatteryPills)) {
          return this._endQuiz('balance'); // reason
        }
        if (Ember.isEmpty(psyhoBatteryPills)) {
          return this._endQuiz('psyho'); // reason
        }
      }
    }),

    actions: {
      returnToQuestion(question) {
        let questions = Ember.get(this, 'questions');
        let balanceBatteryPills = Ember.get(this, 'balanceBattery.pills');

        questions.forEach(element => {
          Ember.set(element, 'isCanReturn', false); // disable canReturn for all questions
          Ember.set(element, 'isActive', false);
        });

        Ember.set(question, 'isActive', true);

        // +1 to Balance
        if (balanceBatteryPills && balanceBatteryPills.length < 4) {
          balanceBatteryPills.unshift(1); // add item to top
          Ember.set(this, 'balanceBattery.pills', balanceBatteryPills);
          this.notifyPropertyChange('balanceBattery');
        }

        setTimeout(() => {
          Ember.set(question, 'isActive', false); // deactivate Question after visiting
          Ember.set(this, 'lastActiveQuestion.isActive', true); // activate current Question
        }, 2000);
      },

      nextQuestion(question, answer) {
        let questions = Ember.get(this, 'questions');

        // set isReturn means POSIBILITY for return to the question
        Ember.set(question, 'isReturn', answer.isReturn);

        questions.forEach(element => {
          Ember.set(element, 'isCanReturn', false); // disable canReturn for all questions

          if (question.id === element.id) {
            Ember.set(element, 'isActive', false);
            Ember.set(element, 'isAnswered', true);

            let nextQuestion = questions.findBy('isAnswered', false);
            if (nextQuestion) {
              Ember.set(nextQuestion, 'isActive', true);
              Ember.set(this, 'lastActiveQuestion', nextQuestion); // set lastActiveQuestion for return after going to back
            } else {
              // End Quiz if nextQuestion is not exists AND isEnded is false
              return Ember.get(this, 'isEnded') ? false : this._endQuiz('end'); // reason
            }
          }

          // set isCanReturn for question with key afterCanReturnTo
          if (element.index === question.afterCanReturnTo) {
            // set isCanReturn means ACTIVATION return action
            Ember.set(element, 'isCanReturn', element.isReturn);
          }
        });

        Ember.set(this, 'questions', questions);
      },

      setResults(answer) {
        Ember.set(this, 'isStarted', true); // trick for the batteryObserver

        let balanceBatteryPills = Ember.get(this, 'balanceBattery.pills');
        let psyhoBatteryPills = Ember.get(this, 'psyhoBattery.pills');

        if (answer.impactOnBalance.status) {
          // here only for Balance in the Quiz
          if (answer.impactOnBalance.isPositive && balanceBatteryPills.length < 4) {
            balanceBatteryPills.unshift(1); // add item to top
          } else {
            balanceBatteryPills.shift(); // remove item from top
          }

          Ember.set(this, 'balanceBattery.pills', balanceBatteryPills);
          this.notifyPropertyChange('balanceBattery');
        }

        if (answer.impactOnPsyho.status) {
          psyhoBatteryPills.shift();
          Ember.set(this, 'psyhoBattery.pills', psyhoBatteryPills);
          this.notifyPropertyChange('psyhoBattery');
        }
      },

      endQuiz(question) {
        Ember.set(question, 'isActive', false);
        Ember.set(question, 'isAnswered', true);

        Ember.set(this, 'isEnded', true);
      }
    },

    _endQuiz(reason) {
      Ember.set(this, 'isEnded', true);
      Ember.set(this, 'reason', reason);
    }
  });
});