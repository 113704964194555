define('frontend/models/poll', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    name: attr('string'),
    total: attr('number'),
    items: attr(),

    isAnswered: attr('boolean', {
      defaultValue: false
    }),
    selectedAnswer: attr('string', {
      defaultValue: ''
    })
  });
});