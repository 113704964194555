define('frontend/components/pizza-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pizza-item'],
    store: Ember.inject.service(),
    item: null,

    didInsertElement() {
      this._super(...arguments);

      if (Ember.get(this, 'item.id') === '6') {
        setTimeout(() => {
          // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          // var ih = iOS ? screen.height : document.body.offsetHeight;
          var ih = document.body.clientHeight;

          this._setHeight(ih);
        }, 500);
      }
    },

    actions: {
      clickOnItem() {
        if (!Ember.get(this, 'item.isCorrect')) {
          Ember.$(this.element).addClass('fade-out');
        } else {
          Ember.$('.pizza-check-box', this.element).addClass('is-galka');
        }

        setTimeout(() => {
          Ember.get(this, 'clickOnItem')(Ember.get(this, 'item'));

          setTimeout(() => {
            // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            // var ih = iOS ? screen.height : document.body.offsetHeight;
            var ih = document.body.clientHeight;

            this._setHeight(ih);
          }, 900);
        }, 1000);
      }
    },

    _setHeight(height) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'set',
          data: {
            id: 'f4af8008-29ba-534f-90bf-3bf3aa6f125b',
            height: `${height}px`
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});