define('frontend/components/ikea-answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pokra-answer'],
    classNameBindings: ['answer.isDisable:disabled'],
    store: Ember.inject.service(),

    answer: null,
    isClicked: false,

    actions: {
      clickAnsewer() {
        if (Ember.get(this, 'answer.isAnswered') || Ember.get(this, 'answer.isDisable')) {
          return;
        } else {
          Ember.set(this, 'isClicked', true);
        }
        if (Ember.get(this, 'clickAnsewer')) {
          Ember.get(this, 'clickAnsewer')(Ember.get(this, 'answer'));
        }
      }
    }
  });
});