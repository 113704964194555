define('frontend/components/senat-answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['boker-answers'],
    classNameBindings: ['isDisabled:disabled'],
    store: Ember.inject.service(),

    answer: null,
    isAnswered: false,
    isCorrectAnswer: false,
    isDisabled: false,

    actions: {
      clickAnsewer() {
        if (Ember.get(this, 'clickAnsewer')) {
          Ember.get(this, 'clickAnsewer')(Ember.get(this, 'answer'));
        }
      }
    }
  });
});