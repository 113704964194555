define('frontend/components/boker-answer-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['boker-answer-block'],
    // classNameBindings: ['isSelected:selected'],
    store: Ember.inject.service(),
    question: null,
    isRightAnswer: false
  });
});