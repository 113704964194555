define('frontend/adapters/-json-api', ['exports', '@ember-data/adapter/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _jsonApi.default;
    }
  });
});