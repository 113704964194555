define('frontend/controllers/village-ikea-wish', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    // questions: alias('model'),
    results: 0,
    isEnded: false

    // actions: {
    //   nextQuestion(question) {
    //     let questions = get(this, 'questions');

    //     questions.forEach((element) => {
    //       if (question.id === element.id) {
    //         set(element, 'isActive', false);
    //         set(element, 'isAnswered', true);

    //         let nextQuestion = questions.findBy('isAnswered', false);
    //         set(nextQuestion, 'isActive', true);
    //       }
    //     });

    //     set(this, 'questions', questions);
    //   },

    //   setResults(number) {
    //     const resultsInit = 0;
    //     let results = isEmpty(get(this, 'results')) ? resultsInit : get(this, 'results');

    //     results = results + number;
    //     set(this, 'results', results);
    //   },

    //   endQuiz(question) {
    //     set(question, 'isActive', false);
    //     set(question, 'isAnswered', true);

    //     set(this, 'isEnded', true);
    //   },
    // },
  });
});