define('frontend/components/ikea-results/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ikea-results'],
    store: Ember.inject.service(),
    results: null,

    maxPositionIs: Ember.computed('results', function () {
      let res = Ember.get(this, 'results') || [];

      return res.indexOf(Math.max(...res));
    }),

    isOne: Ember.computed('maxPositionIs', function () {
      return Ember.get(this, 'maxPositionIs') === 0;
    }),
    isTwo: Ember.computed('maxPositionIs', function () {
      return Ember.get(this, 'maxPositionIs') === 1;
    }),
    isThree: Ember.computed('maxPositionIs', function () {
      return Ember.get(this, 'maxPositionIs') === 2;
    }),
    isFour: Ember.computed('maxPositionIs', function () {
      return Ember.get(this, 'maxPositionIs') === 3;
    })
  });
});