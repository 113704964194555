define('frontend/models/history-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Model, attr } = _emberData.default;

  exports.default = Model.extend({
    question: attr('string'),
    beforeImg: attr('string'),
    afterImg: attr('string'),
    correctAnswerId: attr('string'),
    correctAnswerText: attr('string'),
    correctAnswerTitle: attr('string'),
    wrongAnswerTitle: attr('string'),
    wrongAnswerText: attr('string'),
    answers: attr(),

    isActive: attr('boolean', {
      defaultValue: false
    }),

    isAnswered: attr('boolean', {
      defaultValue: false
    }),

    isLast: attr('boolean', {
      defaultValue: false
    })
  });
});