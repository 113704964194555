define('frontend/adapters/poll', ['exports', 'frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQueryRecord() {
      return 'http://api.quizmachine.io/v1/polls/5f47d8c75d16c24dfd0dd5ec';
      // return 'http://localhost:3000/v1/polls/5f464f08f8eee628c907c960';
    }
  });
});