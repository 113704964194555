define('frontend/components/yota-dec-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    table: null,
    setted: null,

    didInsertElement() {
      this._super(...arguments);

      setTimeout(() => {
        this._setResults();
        this._setHeight(document.documentElement.scrollHeight);
      }, 600);
    },

    didUpdateAttrs() {
      this._setResults();
    },

    _setResults() {
      const res = Ember.get(this, 'results') || [];

      const clientWidth = (window.innerWidth || document.documentElement.clientWidth) * 0.4;

      Ember.$('.res-table').css({
        width: `${clientWidth}px`,
        height: `${clientWidth}px`
      });

      Ember.$('.res-table tr td').css({
        width: `${clientWidth / 5}px`,
        height: `${clientWidth / 5}px`,
        'max-width': `${clientWidth / 5}px`,
        'max-height': `${clientWidth / 5}px`
      });

      res.forEach((element, index) => {
        const id = index + 1;
        let setArr = Ember.get(this, 'setted') || [];

        if (!setArr.includes(id)) {
          Ember.$(`.cell_${id}`).append(`<img src="${element.img}" alt=""/>`);

          setArr.push(id);
          Ember.set(this, 'setted', setArr);

          Ember.$('.res-table tr td img').css({
            height: `${clientWidth / 5 - 20}px`,
            'max-height': `${clientWidth / 5 - 20}px`
          });
        }
      });
    },

    _setHeight(height) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'set',
          data: {
            id: '8f8d27d6-d01f-5d51-848d-74cd53e0129a',
            height: `${height}px`
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});