define('frontend/components/yota-digit-slider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['yota-digit-slider'],
    store: Ember.inject.service(),

    currentValue: null,
    isHidden: false,
    selectedValue: null,
    slider: null,

    type: null,
    values: null,
    startFrom: null,

    offsetForLable: null,

    isHideInput: Ember.computed('type', 'currentValue', function () {
      return this.type === 'gb' && parseInt(this.currentValue) > 50;
    }),

    maxValue: Ember.computed('values', function () {
      return this.values[this.values.length - 1];
    }),

    inputStep: Ember.computed('type', function () {
      return this.type === 'min' ? 10 : 1;
    }),

    isInfinity: Ember.computed('type', function () {
      return this.type === 'gb';
    }),

    sliderClass: Ember.computed('type', function () {
      return `splide-${this.type}`;
    }),

    styleForLable: Ember.computed('offsetForLable', function () {
      return Ember.String.htmlSafe(`transform: matrix(1, 0, 0, 1, ${this.offsetForLable}, 0); opacity: 1;`);
    }),
    typeForLable: Ember.computed('type', function () {
      return this.type === 'min' ? 'Мин' : 'Гб';
    }),

    _initSlider() {
      let slider = new Splide(`.${this.sliderClass}`, {
        perMove: 30,
        perPage: 5,
        focus: 'left',
        arrows: false,
        pagination: false,
        start: this.startFrom,
        swipeDistanceThreshold: 50,
        flickPower: 5000,
        flickMaxPages: 80,
        rewindSpeed: 200,
        autoWidth: true
      });

      slider.on('mounted', () => {
        this._setActivePosition(slider, this.startFrom);
      });

      slider.mount();

      slider.on('move', newIndex => {
        if (newIndex > slider.Components.Elements.slides.length - 1) {
          return false;
        }

        this._setActivePosition(slider, newIndex);
      });

      slider.on('moved', index => {
        const isCasper = $(slider.Components.Elements.slides[index]).hasClass('is_casper');
        const indexOfLastElement = slider.Components.Elements.slides.findIndex(elm => elm.textContent == this.maxValue);

        if (isCasper) {
          slider.go(indexOfLastElement);
        }
      });

      Ember.set(this, 'slider', slider);
    },

    didInsertElement() {
      this._super(...arguments);

      setTimeout(() => {
        this._initSlider();
      }, 600);
    },

    actions: {
      setActive() {
        Ember.set(this, 'isHidden', true);
      },
      setValue(value) {
        const currentElm = this.slider.Components.Elements.slides[this.slider.index];
        const currentValue = currentElm.textContent;

        Ember.set(this, 'isHidden', false);

        if (isNaN(value) || currentValue == value) {
          return false;
        }

        const elem = this.slider.Components.Elements.slides.find((elm, index) => {
          const val = parseInt(value),
                elmVal = parseInt(elm.textContent);

          if (val === elmVal) {
            this.slider.go(index);

            Ember.set(this, 'currentValue', val);

            return true;
          } else if (val <= this.maxValue && val - elmVal < this.inputStep) {
            this.slider.add('<li class="splide__slide">' + value + '</li>', index + 1);

            this.slider.go(index + 1);

            Ember.set(this, 'currentValue', val);

            return true;
          }
        });
      }
    },

    _setActivePosition(slider, index) {
      const new_active_elm = slider.Components.Elements.slides[index];
      const isInfinity = $(new_active_elm).hasClass('is_infinity');

      let new_active_elm_value = new_active_elm.textContent;
      let offsetWidth = new_active_elm.offsetWidth;

      if (isInfinity) {
        new_active_elm_value = 55;
        offsetWidth += 20;
      }

      Ember.set(this, 'offsetForLable', offsetWidth);
      Ember.set(this, 'currentValue', new_active_elm_value);

      this.setSelectedValue(this.type, new_active_elm_value);
    }
  });
});