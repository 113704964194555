define('frontend/controllers/village-history-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    questions: Ember.computed.alias('model'),
    results: 0,
    isEnded: false,

    actions: {
      nextQuestion(question) {
        let questions = Ember.get(this, 'questions');

        questions.forEach(element => {
          if (question.id === element.id) {
            Ember.set(element, 'isActive', false);
            Ember.set(element, 'isAnswered', true);

            let nextQuestion = questions.findBy('isAnswered', false);
            Ember.set(nextQuestion, 'isActive', true);
          }
        });

        Ember.set(this, 'questions', questions);
      },

      setResults(number) {
        const resultsInit = 0;
        let results = Ember.isEmpty(Ember.get(this, 'results')) ? resultsInit : Ember.get(this, 'results');

        results = results + number;
        Ember.set(this, 'results', results);
      },

      endQuiz(question) {
        Ember.set(question, 'isActive', false);
        Ember.set(question, 'isAnswered', true);

        Ember.set(this, 'isEnded', true);
      }
    }
  });
});