define('frontend/components/pokra-answer/pokra-results/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pokra-results'],
    store: Ember.inject.service(),
    results: null,

    didInsertElement() {
      this._super(...arguments);

      setTimeout(() => {
        this._setHeight(document.documentElement.scrollHeight);
      }, 600);
    },

    isOne: Ember.computed('results', function () {
      return Ember.get(this, 'results') <= 3;
    }),
    isTwo: Ember.computed('results', function () {
      return Ember.get(this, 'results') > 3 && Ember.get(this, 'results') <= 8;
    }),
    isThree: Ember.computed('results', function () {
      return Ember.get(this, 'results') > 8 && Ember.get(this, 'results') <= 12;
    }),
    isFour: Ember.computed('results', function () {
      return Ember.get(this, 'results') > 12;
    }),

    _setHeight(height) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'set',
          data: {
            id: '15032c77-f2ea-52db-8dfd-8f3029dcbc57',
            height: `${height}px`
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});