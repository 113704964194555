define('frontend/components/yota-question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['question-yota'],
    classNameBindings: ['question.isActive:active'],
    store: Ember.inject.service(),
    question: null,

    answers: Ember.computed('question.answers', function () {
      return Ember.get(this, 'question.answers');
    }),

    isAnswered: false,
    isCorrectAnswer: false,
    selectedAnswer: null,
    showNext: false,

    isMobile: false,
    isWaiting: false,

    currentAnswer: null,

    actions: {
      selectAnswer(answer) {
        Ember.set(this, 'isWaiting', true);

        Ember.set(this, 'isAnswered', true);
        Ember.set(this, 'selectedAnswer', answer);

        Ember.get(this, 'question.answers').forEach(element => {
          return element.id === answer.id ? Ember.set(element, 'isAnswered', true) : Ember.set(element, 'isDisable', true);
        });

        Ember.get(this, 'setResults')(answer);

        Ember.set(this, 'isWaiting', false);

        Ember.set(this, 'currentAnswer', answer);

        // setTimeout(() => {
        //   return get(this, 'nextQuestion')(get(this, 'question'), answer);
        // }, 8000); // set timeout for transition to the next question

        Ember.set(this, 'showNext', true);
      },

      nextQuestion(question) {
        Ember.get(this, 'nextQuestion')(question, Ember.get(this, 'currentAnswer'));
        Ember.set(this, 'currentAnswer', null);
      },

      endQuiz(question) {
        Ember.get(this, 'endQuiz')(question);
      }
    }
  });
});