define('frontend/components/poll-mts-answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['poll-mts-answer', 'classic', 'light'],
    classNameBindings: ['isAnswered:poll-voted', 'isSelected:selected'],

    store: Ember.inject.service(),
    answer: null,
    total: null,

    isAnswered: false,
    selectedAnswer: null,

    isSelected: Ember.computed('isAnswered', 'selectedAnswer', function () {
      return Ember.get(this, 'isAnswered') && Ember.get(this, 'selectedAnswer') === Ember.get(this, 'answer._id');
    }),

    percentageNumber: Ember.computed('answer.amountOfvotes', 'total', function () {
      const percents = Ember.get(this, 'answer.amountOfvotes') / Ember.get(this, 'total') * 100;
      return Math.round(percents);
    }),

    percentageWidth: Ember.computed('percentageNumber', function () {
      return `width: ${Ember.get(this, 'percentageNumber')}%;`;
    }),

    click() {
      if (Ember.get(this, 'setAnswer') && !Ember.get(this, 'isAnswered')) {
        Ember.get(this, 'setAnswer')(Ember.get(this, 'answer'));
      }
    },

    actions: {}
  });
});