define('frontend/routes/village-advent-2021', ['exports', 'axios'], function (exports, _axios) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(BodyClassMixin, {
    classNames: ['village-advent-2020-body'],
    store: Ember.inject.service('store'),

    model() {
      return Ember.RSVP.hash({
        days: Ember.get(this, 'store').findAll('village-2021-advent'),
        currentDay: _axios.default.get('http://worldtimeapi.org/api/timezone/Europe/Moscow').then(response => {
          return response.json();
        }).then(response => {
          return new Date(response.datetime);
        }).catch(() => {
          return new Date();
        })
      });
    },

    actions: {
      error: function () {
        return true;
      }
    }
  });
});