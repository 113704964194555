define('frontend/components/yota-mem-slider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['yota-mem-slider'],
    classNameBindings: ['isDisabled:disabled'],
    store: Ember.inject.service(),

    slides: null,
    currentIndex: null,

    actions: {
      showPrev() {
        Ember.get(this, 'slides').forEach((element, index) => {
          if (Ember.get(element, 'isSelected')) {
            Ember.set(element, 'isSelected', false);

            if (index === 0) {
              Ember.set(this, 'currentIndex', Ember.get(this, 'slides').length - 1);
            } else {
              Ember.set(this, 'currentIndex', index - 1);
            }
          }
        });

        this._setCurrentSlideSelected();
      },
      showNext() {
        Ember.get(this, 'slides').forEach((element, index) => {
          if (Ember.get(element, 'isSelected')) {
            Ember.set(element, 'isSelected', false);

            if (index === Ember.get(this, 'slides').length - 1) {
              Ember.set(this, 'currentIndex', 0);
            } else {
              Ember.set(this, 'currentIndex', index + 1);
            }
          }
        });

        this._setCurrentSlideSelected();
      }
    },

    _setCurrentSlideSelected() {
      Ember.get(this, 'slides').forEach((element, index) => {
        if (index === Ember.get(this, 'currentIndex')) {
          Ember.set(element, 'isSelected', true);
        }
      });
    }
  });
});