define('frontend/components/history-question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pokra-question'],
    classNameBindings: ['question.isActive:active', 'isAnswered:no-marging'],
    store: Ember.inject.service(),
    question: null,

    didInsertElement() {
      this._super(...arguments);

      setTimeout(() => {
        this._setHeight(document.documentElement.scrollHeight);
      }, 600);
    },

    answers: Ember.computed('question.answers', function () {
      return Ember.get(this, 'question.answers');
    }),

    isAnswered: false,
    isCorrectAnswer: false,
    selectedAnswer: null,

    showNext: false,

    actions: {
      selectAnswer(answer) {
        Ember.set(this, 'isAnswered', true);
        Ember.set(this, 'selectedAnswer', answer);

        if (answer.id === Ember.get(this, 'question').correctAnswerId) {
          Ember.set(this, 'isCorrectAnswer', true);
          Ember.get(this, 'setResults')(1);
        }

        Ember.get(this, 'question.answers').forEach(element => {
          if (element.id === answer.id) {
            Ember.set(element, 'isAnswered', true);
          } else {
            Ember.set(element, 'isDisable', true);
          }
        });

        Ember.set(this, 'showNext', true);
      },

      nextQuestion(question) {
        Ember.get(this, 'nextQuestion')(question);
      },

      endQuiz(question) {
        Ember.get(this, 'endQuiz')(question);
      }
    },

    _setHeight(height) {
      if (this._inIframe()) {
        const parent = window.parent;

        const payload = {
          method: 'set',
          data: {
            id: 'c85f9e41-d846-55d9-8117-2e1f2267094c',
            height: `${height}px`
          }
        };
        parent.postMessage(JSON.stringify(payload), '*');
      }
    },

    _inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});