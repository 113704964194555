define('frontend/components/boker-question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['question-boker'],
    classNameBindings: ['question.isActive:active'],
    store: Ember.inject.service(),
    question: null,
    answers: null,

    isAnswered: false,
    isRightAnswer: false,
    showNext: false,

    actions: {
      selectAnswer(answer) {
        Ember.set(this, 'isAnswered', true);

        if (answer.id === Ember.get(this, 'question.answer.id')) {
          Ember.get(this, 'setResults')(answer, Ember.get(this, 'question.questionKey'));
          Ember.set(this, 'isRightAnswer', true);
        } else {
          Ember.set(this, 'isRightAnswer', false);
        }

        Ember.set(this, 'showNext', true);
      },

      nextQuestion(question) {
        Ember.get(this, 'nextQuestion')(question);
      },

      endQuiz(question) {
        Ember.get(this, 'endQuiz')(question);
      }
    }
  });
});